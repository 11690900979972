body {
  background-color: #282c34;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: white;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {

}

.App a {
  color: #61dafb;
}

.App-button {
  padding: 10px 20px;
  margin: 10px;
  font-size: 20px;
  font-weight: bold;
  border: 2px solid white;
  border-radius: 5px;
  background: #323743;
  color: white;
  animation: App-logo-spin infinite 5s linear;
  cursor: pointer;
}

.App-button-small {
  vertical-align: baseline;
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid white;
  border-radius: 3px;
  background: #323743;
  color: white;
  animation: App-logo-spin infinite 5s linear;
  cursor: pointer;
}

.social-password {
  width: 250px;
  height: 25px;
  display: inline-block;
  padding-right: 10px;
  vertical-align: middle;
  cursor: text;
}

.invite-block {
  padding: 20px 0;
}

.invite {
  vertical-align: middle;
  margin: 0 10px;
  font-size: 14px;
}

.log-out{
  padding-top: 30px;
}

.hidden {
  display: none;
}

.mobile-hint {
    padding-top: 10px;
    font-size: 20px;
}

.mobile-hint code {
    padding: 2px;
}
